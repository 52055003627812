import axios from 'axios';
import { Field, Form, Formik } from 'formik';
import React from 'react';
import {
	Col,
	Container,
	FormGroup,
	FormLabel,
	Row,
	Spinner,
} from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { object } from 'yup';
import { constructURL } from './../../helpers/General';

// Components
import ButtonComponent from './../ButtonComponent/ButtonComponent';

const SearchFormComponent = () => {
	const navigate = useNavigate();

	// Location State
	const {
		state: { searchQuery },
	} = useLocation();

	// Form Schema
	const searchSchema = object().shape({});

	// Form Submission
	const submitSearchForm = async (values, setSubmitting, resetForm) => {
		axios({
			method: 'get',
			url: constructURL('products', { title: values.title }),
			headers: {
				locale: 'en',
			},
		})
			.then((response) => {
				// reset submitting
				setSubmitting(false);

				navigate('/search', {
					state: { searchQuery: values, initialDatasheets: response.data.data },
				});
			})
			.catch((error) => {
				// reset submitting
				setSubmitting(false);

				console.log(error.message);
			});
	};

	return (
		<Container fluid='lg' className='mb-5'>
			<Formik
				initialValues={{
					title: searchQuery.title ?? '',
				}}
				validationSchema={searchSchema}
				onSubmit={(values, { setSubmitting, resetForm }) => {
					setSubmitting(true);
					submitSearchForm(values, setSubmitting, resetForm);
				}}
			>
				{({
					values,
					errors,
					touched,
					handleChange,
					handleBlur,
					handleSubmit,
					isSubmitting,
					setFieldValue,
				}) => (
					<>
						{/* Search Form */}
						<Form
							onSubmit={(event) => {
								event.preventDefault();
								handleSubmit();
							}}
							className='p-4 mx-auto'
							style={{
								backgroundColor: 'rgba(22, 63, 112, 0.15)',
								borderRadius: '0.375rem',
							}}
						>
							<Row className='g-4'>
								{/* Title Filter */}
								<Col xs={12} sm={8}>
									<FormGroup>
										<FormLabel htmlFor='datasheet_title'>
											Product Code
										</FormLabel>
										<Field
											id='datasheet_title'
											type='text'
											placeholder='Enter product code here ...'
											autoComplete='off'
											name='title'
											onChange={handleChange}
											onBlur={handleBlur}
											value={values.title}
											className={`form-control text-capitalize ${
												touched.title && errors.title ? 'is-invalid' : ''
											}`}
										/>
									</FormGroup>
								</Col>

								{/* Submit Form */}
								<Col
									xs={12}
									sm={4}
									className='d-flex justify-content-center align-items-end'
								>
									<FormGroup className='d-flex justify-content-center'>
										<ButtonComponent
											title={isSubmitting ? 'searching ...' : 'search'}
											icon={
												isSubmitting ? (
													<Spinner
														animation='grow'
														variant='light'
														size='sm'
														className='me-2'
													/>
												) : (
													<></>
												)
											}
											styles={{
												button: {
													padding: '0.5rem 1rem',
												},
											}}
											type='submit'
											disabled={isSubmitting ? true : false}
										/>
									</FormGroup>
								</Col>
							</Row>
						</Form>
					</>
				)}
			</Formik>
		</Container>
	);
};

export default SearchFormComponent;
