import { createSlice } from '@reduxjs/toolkit';

// Slice
export const distributorsSlice = createSlice({
	name: 'distributors',
	initialState: {
		distributors: [],
		isDistributorsLoading: true,
	},
	reducers: {
		distributorsSuccess: (state, action) => {
			state.distributors = action.payload;
			state.isDistributorsLoading = false;
		},

		startLoading: (state) => {
			state.isDistributorsLoading = true;
		},

		hasError: (state, action) => {
			state.error = action.payload;
			state.isDistributorsLoading = false;
		},
	},
});

export default distributorsSlice.reducer;
