import { createSlice } from '@reduxjs/toolkit';

// Slice
export const warrantiesSlice = createSlice({
	name: 'warranties',
	initialState: {
		warranties: [],
		isWarrantiesLoading: true,
	},
	reducers: {
		warrantiesSuccess: (state, action) => {
			state.warranties = action.payload;
			state.isWarrantiesLoading = false;
		},

		startLoading: (state) => {
			state.isWarrantiesLoading = true;
		},

		hasError: (state, action) => {
			state.error = action.payload;
			state.isWarrantiesLoading = false;
		},
	},
});

export default warrantiesSlice.reducer;
