import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { constructURL } from '../../helpers/General';

// Actions
import { fetchAboutData } from '../../actions/aboutData.action';

// Styles
import './AboutPage.styles.css';

// Components
import AboutCardComponent from '../../components/AboutCardComponent/AboutCardComponent';
import BreadcrumbComponent from '../../components/BreadcrumbComponent/BreadcrumbComponent';
import LoadingComponent from '../../components/LoadingComponent/LoadingComponent';

const AboutPage = () => {
	// Breadcrumb Content
	const breadcrumbTitle = 'About Us';
	const breadcrumbItems = [
		{ title: 'home', href: '/' },
		{ title: 'about us', href: '/about' },
	];

	// States
	const [isPageLoading, setIsPageLoading] = useState(true);

	// Redux
	const dispatch = useDispatch();
	const { aboutData, isAboutDataLoading } = useSelector(
		(state) => state.aboutData
	);

	useEffect(() => {
		// Scroll To Top
		window.scrollTo(0, 0);

		// Hide Page Loader
		setTimeout(() => {
			setIsPageLoading(false);
		}, 1000);
	}, []);

	useEffect(() => {
		dispatch(fetchAboutData(constructURL('about')));
	}, [dispatch]);

	return isAboutDataLoading || isPageLoading ? (
		<LoadingComponent />
	) : (
		<Container as='main' fluid id='about-page' className='px-0'>
			{/* Breadcrumb */}
			<BreadcrumbComponent items={breadcrumbItems} title={breadcrumbTitle} />

			{/* About Page */}
			<Container fluid='md' className='custom-container p-4'>
				<Row xs={1}>
					{aboutData.map((about, index) => (
						<Col
							key={index}
							className='about-card-component overflow-hidden px-0'
						>
							<AboutCardComponent index={index} about={about} />
						</Col>
					))}
				</Row>
			</Container>
		</Container>
	);
};

export default AboutPage;
