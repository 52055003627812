import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { fetchWarrantyData } from "../../actions/warrantyData.action";

import { Col, Container, Row } from "react-bootstrap";
import { Fade, Slide } from "react-awesome-reveal";

// Components
import BreadcrumbComponent from "../../components/BreadcrumbComponent/BreadcrumbComponent";
import LoadingComponent from "../../components/LoadingComponent/LoadingComponent";
import { constructURL } from "../../helpers/General";
import GeneralCardComponent from "../../components/GeneralCardComponent/GeneralCardComponent";

const WarrantiesPage = () => {
  // Breadcrumb Content
  const breadcrumbTitle = "Warranty";
  const breadcrumbItems = [
    { title: "home", href: "/" },
    { title: "Warranty", href: "/warranties" },
  ];

  // States
  const [isPageLoading, setIsPageLoading] = useState(true);

  // Redux
  const dispatch = useDispatch();
  const { warranties, isWarrantiesLoading } = useSelector(
    (state) => state.warranties
  );

  useEffect(() => {
    // Scroll To Top
    window.scrollTo(0, 0);

    // Hide Page Loader
    setTimeout(() => {
      setIsPageLoading(false);
    }, 1000);
  }, []);

  useEffect(() => {
    dispatch(fetchWarrantyData(constructURL("warranties")));
  }, [dispatch]);
  return isWarrantiesLoading || isPageLoading ? (
    <LoadingComponent />
  ) : (
    <Container as="main" fluid id="general-page" className="px-0">
      {/* Breadcrumb */}
      <BreadcrumbComponent items={breadcrumbItems} title={breadcrumbTitle} />

      {/* Warranty Page */}
      <Container fluid="md" className="custom-container custom-margin py-4">
        <Row
          className="custom-gutter g-4 d-flex justify-content-center align-items-center"
        >
          {warranties.map((warranty, index) => (
            <Col xs={12} md={4}>
              <Fade
                direction="down"
                key={index}
                duration={800}
                delay={index * 100}
              >
                <GeneralCardComponent data={warranty} linkName={"warranties"} />
              </Fade>
            </Col>
          ))}
        </Row>
      </Container>
    </Container>
  );
};

export default WarrantiesPage;
