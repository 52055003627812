import { createSlice } from '@reduxjs/toolkit';

// Slice
export const catalogsSlice = createSlice({
	name: 'catalogs',
	initialState: {
		catalogs: [],
		isCatalogsLoading: true,
	},
	reducers: {
		catalogsSuccess: (state, action) => {
			state.catalogs = action.payload;
			state.isCatalogsLoading = false;
		},

		startLoading: (state) => {
			state.isCatalogsLoading = true;
		},

		hasError: (state, action) => {
			state.error = action.payload;
			state.isCatalogsLoading = false;
		},
	},
});

export default catalogsSlice.reducer;
