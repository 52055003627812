import React, { useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { AiFillHome } from 'react-icons/ai';
import { LinkContainer } from 'react-router-bootstrap';

// Styles
import './NotFoundPage.styles.css';

// Components
import ButtonComponent from './../../components/ButtonComponent/ButtonComponent';

const NotFoundPage = () => {
	// Scroll To Top
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<Container
			as='main'
			fluid
			id='not-found-page'
			className='px-0 d-flex flex-column justify-content-center align-items-center'
		>
			<Row xs={1} className='g-4'>
				<Col className='d-flex flex-column justify-content-center align-items-center'>
					<div className='text-capitalize'>Oops! Page Not Found!</div>
					<div
						className='display-6 fw-bold mt-2 text-capitalize'
						style={{
							fontSize: '2rem',
						}}
					>
						Error 404
					</div>
				</Col>
				<Col>
					<LinkContainer to='/'>
						<ButtonComponent
							title='Go Home'
							icon={
								<AiFillHome
									size={20}
									style={{
										margin: '0 8px 0 0',
									}}
								/>
							}
							styles={{
								button: {
									width: 'fit-content',
									padding: '0.5rem',
									margin: '0 auto',
								},
								icon: {
									marginRight: '0.5rem',
								},
							}}
						/>
					</LinkContainer>
				</Col>
			</Row>
		</Container>
	);
};

export default NotFoundPage;
