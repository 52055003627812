import { createSlice } from '@reduxjs/toolkit';

// Slice
export const homeDataSlice = createSlice({
	name: 'homeData',
	initialState: {
		sliders: [],
		services: [],
		portfolios: [],
		pageOne: {},
		pageTwo: {},
		isHomeDataLoading: true,
	},
	reducers: {
		homeDataSuccess: (state, action) => {
			state.sliders = action.payload['sliders'];
			state.services = action.payload['services'];
			state.portfolios = action.payload['portfolios'];
			state.pageOne = action.payload['pages'][0]['service_section'];
			state.pageTwo = action.payload['pages'][1]['section_one'];
			state.pageThree = action.payload['pages'][2]['section_two'];
			state.isHomeDataLoading = false;
		},

		startLoading: (state) => {
			state.isHomeDataLoading = true;
		},

		hasError: (state, action) => {
			state.error = action.payload;
			state.isHomeDataLoading = false;
		},
	},
});

export default homeDataSlice.reducer;
