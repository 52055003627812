import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';

// Styles
import './CatalogPage.styles.css';

// Components
import BreadcrumbComponent from '../../components/BreadcrumbComponent/BreadcrumbComponent';
import CatalogCardComponent from '../../components/CatalogCardComponent/CatalogCardComponent';
import LoadingComponent from '../../components/LoadingComponent/LoadingComponent';

const CatalogPage = () => {
	// Breadcrumb Content
	const breadcrumbTitle = 'Products';
	const breadcrumbItems = [
		{ title: 'home', href: '/' },
		{ title: 'products', href: '/products' },
	];

	// States
	const [isPageLoading, setIsPageLoading] = useState(true);

	// Redux
	const { catalogs, isCatalogsLoading } = useSelector(
		(state) => state.catalogs
	);

	useEffect(() => {
		// Scroll To Top
		window.scrollTo(0, 0);

		// Hide Page Loader
		setTimeout(() => {
			setIsPageLoading(false);
		}, 1000);
	}, []);

	return isCatalogsLoading || isPageLoading ? (
		<LoadingComponent />
	) : (
		<Container as='main' fluid id='catalog-page' className='px-0'>
			{/* Breadcrumb */}
			<BreadcrumbComponent items={breadcrumbItems} title={breadcrumbTitle} />

			{/* Catalog Page */}
			<Container fluid='md' className='custom-container custom-margin py-4'>
				<Row xs={1} sm={2} className='custom-gutter g-4'>
					{catalogs.map((catalog, index) => (
						<Col key={index}>
							<CatalogCardComponent catalog={catalog} />
						</Col>
					))}
				</Row>
			</Container>
		</Container>
	);
};

export default CatalogPage;
