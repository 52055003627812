import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Fade, Slide, Zoom } from "react-awesome-reveal";
import { LazyLoadImage } from "react-lazy-load-image-component";
import PlaceholderImage from "../../assets/favicon.png";

import { Swiper, SwiperSlide } from "swiper/react";
import {
  Autoplay,
  EffectCoverflow,
  Pagination,
  Navigation,
} from "swiper/modules";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/effect-coverflow";
import "react-lazy-load-image-component/src/effects/blur.css";

import "./PortfolioComponent.scss";

const PortfolioComponent = ({ portfolios }) => {
  const { settings, isSettingsLoading } = useSelector(
    (state) => state.settingsData
  );

  return (
    portfolios.length > 0 && (
      <Container className="custom-container" id="portfolio-section">
        {/* Title */}
        <Row>
          <Zoom duration={1000} delay={200}>
            <Col
              as="h3"
              className="title portfolio-section-title text-uppercase text-center"
            >
              Our Latest Projects
            </Col>
          </Zoom>
        </Row>

        {/* Portfolio Slider */}
        <Zoom duration={1000} delay={300}>
          <Row>
            <Swiper
              slidesPerView={3}
              spaceBetween={30}
              loop={true}
              autoplay={{
                delay: 3000,
                disableOnInteraction: false,
                pauseOnMouseEnter: true,
              }}
              pagination={{
                clickable: true,
                dynamicBullets: true,
              }}
              navigation={true}
              grabCursor={true}
              effect={"coverflow"}
              coverflowEffect={{
                rotate: 50,
                stretch: 0,
                depth: 100,
                modifier: 1,
                slideShadows: true,
              }}
              breakpoints={{
                200: {
                  slidesPerView: 1,
                  spaceBetween: 20,
                },
                768: {
                  slidesPerView: 3,
                  spaceBetween: 20,
                },
                1024: {
                  slidesPerView: 3,
                  spaceBetween: 30,
                },
              }}
              modules={[Autoplay, EffectCoverflow, Navigation, Pagination]}
              className="portfolio-slider"
            >
              {portfolios?.map((portfolio, index) => (
                <SwiperSlide key={index}>
                  <Card>
                    <div className="portfolio-image">
                      <LazyLoadImage
                        key={portfolio?.id}
                        src={portfolio?.image}
                        alt={"Portfolio image"}
                        className="img-fluid w-100"
                        placeholderSrc={PlaceholderImage}
                        effect="blur"
                      />
                    </div>
                    <Card.Body>
                      <Card.Title
                        className="title text-limit text-uppercase .portfolio-title"
                        style={{
                          "--lines": "1",
                        }}
                      >
                        {portfolio?.title}
                      </Card.Title>
                      <Card.Text
                        className="text-limit description"
                        style={{ "--lines": 3 }}
                        dangerouslySetInnerHTML={{
                          __html: portfolio?.description,
                        }}
                      ></Card.Text>
                    </Card.Body>
                  </Card>
                </SwiperSlide>
              ))}
            </Swiper>
          </Row>
        </Zoom>
      </Container>
    )
  );
};

export default PortfolioComponent;
