import axios from 'axios';
import { aboutDataSlice } from '../reducers/aboutData.reducer';

const { aboutDataSuccess, startLoading, hasError } = aboutDataSlice.actions;

// Actions
export const fetchAboutData =
	(url = 'https://solextechnology.com/backend/api/about', language = 'en') =>
	async (dispatch) => {
		dispatch(startLoading());

		await axios({
			method: 'get',
			url: url,
			headers: {
				locale: language,
			},
		})
			.then((response) => {
				dispatch(aboutDataSuccess(response.data.data));
			})
			.catch((error) => dispatch(hasError(error.message)));
	};
