import React from 'react';
import { ToastContainer } from 'react-toastify';

// Components
import FooterComponent from '../../components/FooterComponent/FooterComponent';
import MetaTagsComponent from '../../components/MetaTagsComponent/MetaTagsComponent';
import NavbarComponent from '../../components/NavbarComponent/NavbarComponent';

const MainTemplatePage = ({ children }) => {
	return (
		<>
			{/* Toast Messages */}
			<ToastContainer
				position='top-right'
				autoClose={5000}
				newestOnTop
				pauseOnHover
				rtl={false}
			/>

			{/* Navbar */}
			<NavbarComponent />

			{/* Main Content */}
			{children}

			{/* Footer */}
			<FooterComponent />

			{/* Meta Data */}
			<MetaTagsComponent />
		</>
	);
};

export default MainTemplatePage;
