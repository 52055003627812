import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { fetchNewsData } from "../../actions/newsData.action";
import { useParams } from "react-router-dom";

import { Col, Container, Row } from "react-bootstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Fade, Slide, Zoom } from "react-awesome-reveal";

// Components
import BreadcrumbComponent from "../../components/BreadcrumbComponent/BreadcrumbComponent";
import LoadingComponent from "../../components/LoadingComponent/LoadingComponent";
import { constructURL, trimEmptyTags } from "../../helpers/General";
import PlaceholderImage from "../../assets/favicon.png";
import LightboxComponent from "../../components/LightBox/LightboxComponent";

import "react-lazy-load-image-component/src/effects/blur.css";
import "./SingleNewsPage.scss";

const SingleNewsPage = () => {
  const { news_id } = useParams();
  // States
  const [isPageLoading, setIsPageLoading] = useState(true);

  // Redux
  const dispatch = useDispatch();
  const { news, isNewsLoading } = useSelector((state) => state.news);

  useEffect(() => {
    // Scroll To Top
    window.scrollTo(0, 0);

    // Hide Page Loader
    setTimeout(() => {
      setIsPageLoading(false);
    }, 1000);
  }, []);

  useEffect(() => {
    dispatch(fetchNewsData(constructURL("news")));
  }, [dispatch]);

  // States
  const [lightbox, setLightbox] = useState({ isOpen: false, index: 0 });

  // Find single news
  const data = news?.find((data) => parseInt(data?.id) === parseInt(news_id));
  // Breadcrumb Content
  const breadcrumbTitle = data?.title;
  const breadcrumbItems = [
    { title: "home", href: "/" },
    { title: "News", href: "/news" },
    { title: data?.title, href: "" },
  ];

  return isNewsLoading || isPageLoading ? (
    <LoadingComponent />
  ) : (
    <Container as="main" fluid id="news-page" className="px-0">
      {/* Breadcrumb */}
      <BreadcrumbComponent items={breadcrumbItems} title={breadcrumbTitle} />

      {/* data Page */}
      <Container fluid="md" className="custom-container custom-margin py-4">
        <Row className="custom-gutter g-4 single-news-row">
          {/* Image */}
          <Col xs={12} sm={12} md={4}>
            <Fade direction="left" cascade damping={0.1} delay={90}>
              <div className="single-news-image">
                <LazyLoadImage
                  key={data?.id}
                  src={data?.image}
                  alt={"News image"}
                  className="img-fluid w-100"
                  placeholderSrc={PlaceholderImage}
                  effect="blur"
                  onClick={() => setLightbox({ isOpen: true, index: 0 })}
                />
              </div>
            </Fade>
          </Col>

          {/* Title and Description */}
          <Col xs={12} sm={6} md={8}>
            <Fade direction="right" damping={0.1} delay={90}>
              {/* Title */}
              <h3 className="title">{data?.title}</h3>

              {/* Description */}
              <div
                dangerouslySetInnerHTML={{
                  __html: trimEmptyTags(data?.description),
                }}
              ></div>
            </Fade>
          </Col>
        </Row>

        <LightboxComponent
          slides={[data]}
          lightbox={lightbox}
          setLightbox={setLightbox}
          pathname="<object>.image"
        />
      </Container>
    </Container>
  );
};
export default SingleNewsPage;
