import React from 'react';
import { Card } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { trimEmptyTags } from '../../helpers/General';

// Styles
import './ServiceCardComponent.styles.css';

const ServiceCardComponent = ({ service }) => {
	const { settings, isSettingsLoading } = useSelector(
		(state) => state.settingsData
	);

	return isSettingsLoading ? (
		<></>
	) : (
		<Card className='service-card-component overflow-hidden'>
			<div className='image-container overflow-hidden'>
				<Card.Img
					variant='top'
					src={service.image}
					alt='Service Preview'
					onError={(event) => {
						event.target.src = settings.loader;
					}}
					className='w-100 img-fluid'
					style={{
						objectFit: 'fill',
						objectPosition: 'center',
					}}
				/>
			</div>
			<Card.Body>
				<Card.Title
					as='h4'
					className='title text-limit text-uppercase'
					style={{
						'--lines': '1',
					}}
				>
					{service.title}
				</Card.Title>


				<Card.Text
					className='text-limit p-0'
					style={{
						// '--lines': '6',
					}}
					dangerouslySetInnerHTML={{
						__html: trimEmptyTags(service.description),
					  }}
				>
					
				</Card.Text>
			</Card.Body>
		</Card>
	);
};

export default ServiceCardComponent;
