import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';

// Reducers
import aboutData from '../reducers/aboutData.reducer';
import catalogs from '../reducers/catalogs.reducer';
import warranties from '../reducers/warranties.reducer';
import news from '../reducers/news.reducer';
import distributors from '../reducers/distributors.reducer';
import homeData from '../reducers/homeData.reducer';
import products from '../reducers/products.reducer';
import settingsData from '../reducers/settingsData.reducer';

const reducer = combineReducers({
	catalogs,
	warranties,
	news,
	distributors,
	products,
	homeData,
	aboutData,
	settingsData,
});

const store = configureStore({
	reducer,
});

export default store;
