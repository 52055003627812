import React, { useEffect, useState } from "react";
import { Fade, Slide, Zoom } from "react-awesome-reveal";
import { Col, Container, Image, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// import required modules
import { Autoplay, EffectFade, Pagination, Navigation } from "swiper/modules";
import { trimEmptyTags } from "../../helpers/General";

// Styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "./HomePage.styles.css";

// Components
import ContactFormComponent from "../../components/ContactFormComponent/ContactFormComponent";
import LoadingComponent from "../../components/LoadingComponent/LoadingComponent";
import ServiceCardComponent from "../../components/ServiceCardComponent/ServiceCardComponent";
import PortfolioComponent from "../../components/PortfolioComponent/PortfolioComponent";

const HomePage = () => {
  // States
  const [isPageLoading, setIsPageLoading] = useState(true);

  // Redux
  const {
    sliders,
    services,
    portfolios,
    pageOne,
    pageTwo,
    pageThree,
    isHomeDataLoading,
  } = useSelector((state) => state.homeData);
  const { settings, isSettingsLoading } = useSelector(
    (state) => state.settingsData
  );

  useEffect(() => {
    // Scroll To Top
    window.scrollTo(0, 0);

    // Hide Page Loader
    setTimeout(() => {
      setIsPageLoading(false);
    }, 1000);
  }, []);

  return isHomeDataLoading || isSettingsLoading || isPageLoading ? (
    <LoadingComponent />
  ) : (
    <Container as="main" fluid id="home-page" className="px-0">
      {/* Slider */}
      <Container
        fluid
        id="slider-section"
        className="px-0"
        style={{
          paddingTop: "3rem",
          height: "100vh",
        }}
      >
        <Swiper
          slidesPerView={1}
          spaceBetween={0}
          centeredSlides={true}
          loop={true}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
          }}
          effect={"fade"}
          fadeEffect={{ crossFade: true }}
          speed={1000}
          pagination={{
            dynamicBullets: true,
          }}
          modules={[EffectFade, Autoplay, Pagination]}
          className="mySwiper"
          style={{
            height: "100%",
          }}
        >
          {sliders.map((slider, index) => (
            <SwiperSlide
              key={index}
              className="d-flex flex-column justify-content-center align-items-center"
              style={{
                "--image-url": `url(${slider.image})`,
                "--loader-url": `url(${settings.loader})`,
              }}
            >
              <div
                className="custom-container slider-content animate__animated animate__fadeInUp animate__delay-1 px-2"
                style={{
                  "--animate-delay": "0.5s",
                }}
              >
                <h3 className="title text-uppercase px-0">{slider.title}</h3>
                <p className="px-0">{slider.description}</p>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </Container>

      {/* Services */}
      <Container
        fluid
        id="services-section"
        className="custom-margin overflow-hidden px-0"
        style={{
          minHeight: "50vh",
        }}
      >
        <Container fluid="md" className="custom-container overflow-hidden p-4">
          <Row xs={1} className="g-4">
            <Slide direction="down" duration={800} delay={100}>
              <Col
                className="section-head"
                style={{
                  marginBottom: "2rem",
                }}
              >
                <h3 className="title text-uppercase text-center">
                  {pageOne.title}
                </h3>
                <p
                  className="text-center"
                  dangerouslySetInnerHTML={{
                    __html: trimEmptyTags(pageOne.description),
                  }}
                ></p>
              </Col>
            </Slide>
            <Col>
              <Row
                xs={1}
                md={3}
                className="custom-gutter d-flex justify-content-center align-items-center"
              >
                {services.map((service, index) => (
                  <Fade key={index} delay={index * 200}>
                    <Col>
                      <ServiceCardComponent service={service} />
                    </Col>
                  </Fade>
                ))}
              </Row>
            </Col>
          </Row>
        </Container>
      </Container>

      {/* Separator */}
      <Container
        fluid
        id="plain-section"
        as="section"
        className="custom-margin separator-section d-flex justify-content-center align-items-center"
        style={{
          minHeight: "65vh",
        }}
      >
        <Container
          fluid="md"
          className="custom-container"
          style={{
            height: "100%",
          }}
        >
          <Zoom duration={1000} delay={200}>
            <div className="text-center">
              <h3 className="title text-uppercase">{pageTwo.title}</h3>
              <p
                dangerouslySetInnerHTML={{
                  __html: trimEmptyTags(pageTwo.description),
                }}
              ></p>
            </div>
          </Zoom>
        </Container>
      </Container>

      {/* Content With Side Image */}
      <Container
        fluid
        id="image-section"
        className="custom-margin px-0 mt-3"
        style={{
          backgroundColor: "#f1f2f3",
        }}
      >
        <Container fluid className="section-two">
          <Row
            style={{
              minHeight: "50vh",
            }}
          >
            <Col
              xs={12}
              md={6}
              lg={4}
              className="content-with-image d-flex flex-column justify-content-center py-5"
            >
              <h3 className="title text-uppercase">{pageThree.title}</h3>
              <p
                dangerouslySetInnerHTML={{
                  __html: trimEmptyTags(pageThree.description),
                }}
              ></p>
            </Col>
            <Col
              xs={{ span: "12", order: "first" }}
              md={{ span: "6", order: "last" }}
              lg={{ span: "8", order: "last" }}
            >
              <Image
                fluid
                src={pageThree.image}
                alt="Section Preview"
                onError={(event) => {
                  event.target.src = settings.loader;
                }}
                className="w-100 h-100"
                style={{
                  objectFit: "cover",
                  objectPosition: "center",
                }}
              />
            </Col>
          </Row>
        </Container>
      </Container>

      {/* Portfolio */}
      <PortfolioComponent portfolios={portfolios} />

      {/* Ask About Products */}
      <Container
        fluid
        className="custom-margin p-4 px-0"
        style={{
          minHeight: "50vh",
        }}
      >
        <Container fluid="md" className="custom-container">
          <Row xs={1} className="g-4 overflow-hidden">
            {/* <Slide direction='down' duration={800} delay={100}> */}
            <Col
              as="h3"
              className="title text-uppercase text-center"
              style={{
                marginBottom: "2rem",
              }}
            >
              Ask About Products
            </Col>
            {/* </Slide> */}
            <Col className="overflow-hidden">
              {/* <Zoom duration={1000} delay={200}> */}
              <ContactFormComponent isFullWidth={true} />
              {/* </Zoom> */}
            </Col>
          </Row>
        </Container>
      </Container>
    </Container>
  );
};

export default HomePage;
