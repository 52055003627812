import React from 'react';
import { Card } from 'react-bootstrap';
import { FaDownload } from 'react-icons/fa';

// Styles
import './SearchResultComponent.styles.css';

// Components
import ButtonComponent from './../ButtonComponent/ButtonComponent';

const SearchResultComponent = ({ datasheet }) => {
	return (
		<Card className='text-center'>
			<Card.Body>
				<Card.Title
					className='text-limit text-capitalize'
					style={{
						'--lines': 1,
					}}
				>
					{datasheet.title.toLowerCase()}
				</Card.Title>
				<Card.Subtitle
					className='text-limit mb-3 text-muted text-uppercase'
					style={{
						'--lines': 1,
					}}
				>{`@${datasheet.category.toLowerCase()}`}</Card.Subtitle>
				<ButtonComponent
					title='Download'
					href={datasheet.pdf}
					target={true}
					icon={
						<FaDownload
							className='position-relative'
							style={{
								margin: '0 8px 0 0',
								zIndex: '10',
							}}
						/>
					}
					styles={{
						button: {
							width: 'fit-content',
							margin: '0 auto',
							padding: '0.5rem',
						},
					}}
				/>
			</Card.Body>
		</Card>
	);
};

export default SearchResultComponent;
