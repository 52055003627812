import axios from 'axios';
import { distributorsSlice } from '../reducers/distributors.reducer';

const { distributorsSuccess, startLoading, hasError } = distributorsSlice.actions;

// Actions
export const fetchDistributorsData =
	(url = 'https://solextechnology.com/backend/api/distributors', language = 'en') =>
	async (dispatch) => {
		dispatch(startLoading());

		await axios({
			method: 'get',
			url: url,
			headers: {
				locale: language,
			},
		})
			.then((response) => {
				dispatch(distributorsSuccess(response.data.data));
			})
			.catch((error) => dispatch(hasError(error.message)));
	};
