import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';

// Styles
import './SearchResultPage.styles.css';

// Components
import BreadcrumbComponent from '../../components/BreadcrumbComponent/BreadcrumbComponent';
import LoadingComponent from '../../components/LoadingComponent/LoadingComponent';
import SearchFormComponent from '../../components/SearchFormComponent/SearchFormComponent';
import NoResultComponent from './../../components/NoResultComponent/NoResultComponent';
import SearchResultComponent from './../../components/SearchResultComponent/SearchResultComponent';

const SearchResultPage = () => {
	// Breadcrumb Content
	const breadcrumbTitle = 'Search Results';
	const breadcrumbItems = [
		{ title: 'home', href: '/' },
		{ title: 'search results', href: '/search' },
	];

	// States
	const [datasheets, setDatasheets] = useState(null);
	const [filteredDatasheets, setFilteredDatasheets] = useState(null);
	const [isPageLoading, setIsPageLoading] = useState(true);

	// Location State
	const {
		state: { searchQuery, initialDatasheets },
	} = useLocation();

	useEffect(() => {
		// Scroll To Top
		window.scrollTo(0, 0);

		// Hide Page Loader
		setTimeout(() => {
			setIsPageLoading(false);
		}, 1000);
	}, []);

	// Fetch Initial Datasheets
	useEffect(() => {
		setDatasheets(initialDatasheets);
		setFilteredDatasheets(initialDatasheets);
	}, [initialDatasheets]);

	return !datasheets || isPageLoading ? (
		<LoadingComponent />
	) : (
		<Container as='main' fluid id='search-page' className='px-0'>
			{/* Breadcrumb */}
			<BreadcrumbComponent items={breadcrumbItems} title={breadcrumbTitle} />

			{/* Search Results Page */}
			<Container fluid='md' className='custom-container custom-margin py-4'>
				<Row xs={1} className='g-4'>
					{/* Search Form */}
					<Col>
						<SearchFormComponent />
					</Col>

					{/* Search Results */}
					<Col>
						<Row xs={1} sm={2} lg={3} className='g-4'>
							{filteredDatasheets && filteredDatasheets.length !== 0 ? (
								filteredDatasheets.map((datasheet, index) => (
									<Col key={index}>
										<SearchResultComponent datasheet={datasheet} />
									</Col>
								))
							) : (
								<NoResultComponent />
							)}
						</Row>
					</Col>
				</Row>
			</Container>
		</Container>
	);
};

export default SearchResultPage;
