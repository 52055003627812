import axios from 'axios';
import { warrantiesSlice } from '../reducers/warranties.reducer';

const { warrantiesSuccess, startLoading, hasError } = warrantiesSlice.actions;

// Actions
export const fetchWarrantyData =
	(url = 'https://solextechnology.com/backend/api/warranties', language = 'en') =>
	async (dispatch) => {
		dispatch(startLoading());

		await axios({
			method: 'get',
			url: url,
			headers: {
				locale: language,
			},
		})
			.then((response) => {
				dispatch(warrantiesSuccess(response.data.data));
			})
			.catch((error) => dispatch(hasError(error.message)));
	};
