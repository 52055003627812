import axios from "axios";
import { Field, Form, Formik } from "formik";
import React from "react";
import {
  CloseButton,
  Col,
  FormGroup,
  FormLabel,
  Modal,
  Row,
  Spinner,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { object } from "yup";
import { constructURL } from "./../../helpers/General";

// Styles
import "./SearchModalComponent.styles.css";

// Components
import ButtonComponent from "./../ButtonComponent/ButtonComponent";

const SearchModalComponent = ({ ...props }) => {
  const navigate = useNavigate();

  // Form Schema
  const searchSchema = object().shape({});

  // Form Submission
  const submitSearchForm = async (values, setSubmitting, resetForm) => {
    axios({
      method: "get",
      url: constructURL("products", { title: values.title }),
      headers: {
        locale: "en",
      },
    })
      .then((response) => {
        props.onHide();

        navigate("/search", {
          state: { searchQuery: values, initialDatasheets: response.data.data },
        });
      })
      .catch((error) => console.log(error.message));
  };

  return (
    <Modal
      dir="ltr"
      lang="en"
      id="search-modal"
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          Search for data sheet
        </Modal.Title>
        <CloseButton
          className="close-modal mx-0 ms-auto"
          aria-label="Hide"
          onClick={props.onHide}
        />
      </Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={{
            title: "",
          }}
          validationSchema={searchSchema}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            setSubmitting(true);
            submitSearchForm(values, setSubmitting, resetForm);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
          }) => (
            <>
              {/* Search Form */}
              <Form
                onSubmit={(event) => {
                  event.preventDefault();
                  handleSubmit();
                }}
              >
                <Row xs={1} className="g-4">
                  {/* Title Filter */}
                  <FormGroup as={Col} className="mb-3">
                    <FormLabel htmlFor="datasheet_title">
                      Product Code
                    </FormLabel>
                    <Field
                      id="datasheet_title"
                      type="text"
                      placeholder="Enter product code here .."
                      autoComplete="off"
                      name="title"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.title}
                      className={`form-control text-capitalize ${
                        touched.title && errors.title ? "is-invalid" : ""
                      }`}
                    />
                  </FormGroup>
                </Row>

                {/* Submit Form */}
                <FormGroup className="d-flex justify-content-center mt-3">
                  <ButtonComponent
                    title={isSubmitting ? "searching ..." : "search"}
                    icon={
                      isSubmitting ? (
                        <Spinner
                          animation="grow"
                          variant="light"
                          size="sm"
                          className="me-2"
                        />
                      ) : (
                        <></>
                      )
                    }
                    styles={{
                      button: {
                        padding: "0.5rem 1rem",
                      },
                    }}
                    type="submit"
                    disabled={isSubmitting ? true : false}
                  />
                </FormGroup>
              </Form>
            </>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default SearchModalComponent;
