import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { FaTelegramPlane } from 'react-icons/fa';
import { LinkContainer } from 'react-router-bootstrap';

// Styles
import './NoResultComponent.styles.css';

// Components
import ButtonComponent from './../../components/ButtonComponent/ButtonComponent';

const NoResultComponent = () => {
	return (
		<Container
			as='section'
			fluid
			id='no-result-component'
			className='px-2 w-100 mt-3 text-center'
		>
			<Row xs={1} className='g-4'>
				<Col className='d-flex flex-column justify-content-center align-items-center'>
					<div>Oops! No results found!</div>
					<div
						className='display-6 fw-bold mt-2'
						style={{
							fontSize: '2rem',
						}}
					>
						Feel free to contact us, or try another search!
					</div>
				</Col>
				<Col>
					<LinkContainer to='/contact'>
						<ButtonComponent
							title='Contact Us'
							icon={
								<FaTelegramPlane
									size={20}
									style={{
										margin: '0 8px 0 0',
									}}
								/>
							}
							styles={{
								button: {
									width: 'fit-content',
									padding: '0.5rem',
									margin: '0 auto',
								},
								icon: {
									marginRight: '0.5rem',
								},
							}}
						/>
					</LinkContainer>
				</Col>
			</Row>
		</Container>
	);
};

export default NoResultComponent;
