import axios from 'axios';
import { newsSlice } from '../reducers/news.reducer';

const { newsSuccess, startLoading, hasError } = newsSlice.actions;

// Actions
export const fetchNewsData =
	(url = 'https://solextechnology.com/backend/api/news', language = 'en') =>
	async (dispatch) => {
		dispatch(startLoading());

		await axios({
			method: 'get',
			url: url,
			headers: {
				locale: language,
			},
		})
			.then((response) => {
				dispatch(newsSuccess(response.data.data));
			})
			.catch((error) => dispatch(hasError(error.message)));
	};
