import React, { useState } from 'react';
import {
	Col,
	Container,
	FormControl,
	FormGroup,
	Image,
	Row,
} from 'react-bootstrap';
import { BsEnvelope, BsPhone } from 'react-icons/bs';
import { FiPhone } from 'react-icons/fi';
import { IoLocationOutline } from 'react-icons/io5';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

// Styles
import './FooterComponent.styles.css';

// Components
import SearchModalComponent from './../SearchModalComponent/SearchModalComponent';
import TextWithIconComponent from './../TextWithIconComponent/TextWithIconComponent';

const FooterComponent = () => {
	// States
	const [isSearchShown, setIsSearchShown] = useState(false);

	// Redux
	const { settings, contacts, isSettingsLoading } = useSelector(
		(state) => state.settingsData
	);

	const ICON = {
		SIZE: 24,
		COLOR: '',
	};

	return isSettingsLoading ? (
		<></>
	) : (
		<>
			<Container
				as='footer'
				fluid
				id='footer-component'
				className='p-3 px-0 my-0'
				style={{
					'--image-url': `url(${settings.footer_img})`,
					'--loader-url': `url(${settings.loader})`,
				}}
			>
				<Container fluid='md' className='footer'>
					<Row>
						{/* Contact Information */}
						<Col xs={12} sm={7} md={6} xl={4}>
							{/* Company Logo */}
							<Image
								fluid
								id='footer-logo'
								src={settings.white_logo}
								alt='company logo'
								onError={(event) => {
									event.target.src = settings.loader;
								}}
								className='d-block w-100 text-capitalize'
								style={{
									maxWidth: '10rem',
									maxHeight: '5rem',
								}}
							/>

							{/* Address */}
							<TextWithIconComponent
								mainIcon={
									<IoLocationOutline
										size={ICON.SIZE}
										className='contact-icon'
									/>
								}
								mainText={settings.address.toLowerCase()}
								isCentered={false}
							/>

							{/* Telephones */}
							{contacts.telephone &&
								contacts.telephone.map((telephone, index) => (
									<a
										key={index}
										href={`tel:${telephone.contact}`}
										className='contact'
									>
										<TextWithIconComponent
											mainIcon={
												<FiPhone size={ICON.SIZE} className='contact-icon' />
											}
											mainText={telephone.contact}
										/>
									</a>
								))}

							{/* Phones */}
							{contacts.mobile &&
								contacts.mobile.map((phone, index) => (
									<a
										key={index}
										href={`tel:${phone.contact}`}
										className='contact'
									>
										<TextWithIconComponent
											mainIcon={
												<BsPhone size={ICON.SIZE} className='contact-icon' />
											}
											mainText={phone.contact}
										/>
									</a>
								))}
						</Col>

						{/* Important Links */}
						<Col xs={12} sm={1} md={1} xl={1}></Col>
						<Col xs={12} sm={4} md={5} xl={2}>
							<h5 className='title text-uppercase'>Links</h5>
							<ul className='links-container'>
								<Link to='/products' className='important-link'>
									<li>Datasheets</li>
								</Link>
								<Link to='/about' className='important-link'>
									<li>About</li>
								</Link>
								{/* <Link to='/news' className='important-link'>
									<li>News</li>
								</Link> */}
								<Link to='/contact' className='important-link'>
									<li>Contact</li>
								</Link>
							</ul>
						</Col>
						<Col xs={12} xl={1}></Col>

						{/* Search Bar and Emails */}
						<Col xs={12} sm={12} xl={4} className='my-3'>
							{/* Search Bar */}
							<FormGroup
								role='button'
								className='d-flex ms-auto mb-2'
								onClick={() => setIsSearchShown(true)}
							>
								{/* Search Icon */}
								<FormControl placeholder='Enter product code here ...' />
							</FormGroup>

							{/* Search Modal */}
							<SearchModalComponent
								show={isSearchShown}
								onHide={() => setIsSearchShown(false)}
							/>

							{/* Emails */}
							{contacts.email &&
								contacts.email.map((email, index) => (
									<a
										key={index}
										href={`mailto:${email.contact}`}
										className='contact'
									>
										<TextWithIconComponent
											mainIcon={
												<BsEnvelope size={ICON.SIZE} className='contact-icon' />
											}
											mainText={email.contact}
											isCapitalized={false}
										/>
									</a>
								))}
						</Col>

						{/* Copyrights */}
						<Col xs={12} id='copyrights' className='text-center text-uppercase'>
							{settings.copyrights.toLowerCase()}
						</Col>
					</Row>
				</Container>
			</Container>
		</>
	);
};

export default FooterComponent;
