import React, { useEffect, useState } from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import { BsEnvelope, BsPhone } from "react-icons/bs";
import { FiPhone } from "react-icons/fi";
import { IoLocationOutline } from "react-icons/io5";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBlenderPhone,
  faEnvelope,
  faFax,
  faMapMarkedAlt,
  faMapMarkerAlt,
  faMobileAlt,
} from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

// Components
import BreadcrumbComponent from ".././../components/BreadcrumbComponent/BreadcrumbComponent";
import ContactFormComponent from "./../../components/ContactFormComponent/ContactFormComponent";
import LoadingComponent from "./../../components/LoadingComponent/LoadingComponent";
import TextWithIconComponent from "./../../components/TextWithIconComponent/TextWithIconComponent";
import { Zoom } from "react-awesome-reveal";

// Styles
import "./ContactPage.styles.css";

const ContactPage = () => {
  // Breadcrumb Content
  const breadcrumbTitle = "Contact Us";
  const breadcrumbItems = [
    { title: "home", href: "/" },
    { title: "contact us", href: "/contact" },
  ];

  // States
  const [isPageLoading, setIsPageLoading] = useState(true);

  // Redux
  const { settings, contacts, branches, isSettingsLoading } = useSelector(
    (state) => state.settingsData
  );

  const ICON = {
    SIZE: 24,
    COLOR: "",
  };

  useEffect(() => {
    // Scroll To Top
    window.scrollTo(0, 0);

    // Hide Page Loader
    setTimeout(() => {
      setIsPageLoading(false);
    }, 1000);
  }, []);

  return isSettingsLoading || isPageLoading ? (
    <LoadingComponent />
  ) : (
    <Container
      as="main"
      fluid
      id="contact-page"
      className="position-relative px-0"
    >
      {/* Breadcrumb */}
      <BreadcrumbComponent items={breadcrumbItems} title={breadcrumbTitle} />

      {/* Contact Page */}
      <Container fluid="md" className="custom-container custom-margin py-4">
        <Row className="g-4">
          {/* Map */}
          <Col
            xs={12}
            xl={8}
            id="map"
            dangerouslySetInnerHTML={{
              __html: settings.map,
            }}
          ></Col>

          {/* Contact Information */}
          <Col xs={12} xl={4} id="contact-info">
            {/* Company Logo */}
            {/* <Image
							fluid
							src={settings.logo}
							alt='company logo'
							onError={(event) => {
								event.target.src = settings.loader;
							}}
							className='d-block mx-auto w-100 text-capitalize'
							style={{
								maxWidth: '10rem',
								maxHeight: '5rem',
							}}
						/> */}

            {/* Address */}
            <TextWithIconComponent
              mainIcon={
                <IoLocationOutline size={ICON.SIZE} className="contact-icon" />
              }
              mainText={settings.address.toLowerCase()}
              isCentered={false}
            />

            {/* Telephones */}
            {contacts.telephone &&
              contacts.telephone.map((telephone, index) => (
                <a
                  key={index}
                  href={`tel:${telephone.contact}`}
                  className="contact"
                >
                  <TextWithIconComponent
                    mainIcon={
                      <FiPhone size={ICON.SIZE} className="contact-icon" />
                    }
                    mainText={telephone.contact}
                  />
                </a>
              ))}

            {/* Phones */}
            {contacts.mobile &&
              contacts.mobile.map((phone, index) => (
                <a
                  key={index}
                  href={`tel:${phone.contact}`}
                  className="contact"
                >
                  <TextWithIconComponent
                    mainIcon={
                      <BsPhone size={ICON.SIZE} className="contact-icon" />
                    }
                    mainText={phone.contact}
                  />
                </a>
              ))}

            {/* Emails */}
            {contacts.email &&
              contacts.email.map((email, index) => (
                <a
                  key={index}
                  href={`mailto:${email.contact}`}
                  className="contact"
                >
                  <TextWithIconComponent
                    mainIcon={
                      <BsEnvelope size={ICON.SIZE} className="contact-icon" />
                    }
                    mainText={email.contact}
                    isCapitalized={false}
                  />
                </a>
              ))}
          </Col>
        </Row>

        {/* Branches */}
        <Row className="g-3 branch-row">
          {branches?.map((branch, index) => (
            <Col xm={12} sm={12} md={4} key={index}>
              {/* Title */}
              <div className="branch-title">
                <FontAwesomeIcon
                  icon={faMapMarkedAlt}
                  className="branch-title-icon"
                />
                {branch?.type_key == "in_charge_contact"
                  ? `${branch?.title}`
                  : `${branch?.type}: ${branch?.title}`}
              </div>

              {/* Address */}
              {branch?.address?.length > 0 && (
                <div>
                  <FontAwesomeIcon
                    icon={faMapMarkerAlt}
                    className="branch-title-icon"
                  />
                  <label>Address:</label> {branch?.address}
                </div>
              )}

              {/* Mobile */}
              {branch?.mobile?.length > 0 && (
                <div className="mt-2">
                  <FontAwesomeIcon
                    icon={faMobileAlt}
                    className="branch-title-icon"
                  />
                  <label>Mobile: &nbsp;</label>
                  <label className="contact">
                    <Link to={`tel:${branch?.mobile}`}>{branch?.mobile}</Link>
                  </label>
                </div>
              )}

              {/* Mobile2 */}
              {branch?.mobile2?.length > 0 && (
                <div className="mt-2">
                  <FontAwesomeIcon
                    icon={faMobileAlt}
                    className="branch-title-icon"
                  />
                  <label>Mobile: &nbsp;</label>
                  <label className="contact">
                    <Link to={`tel:${branch?.mobile2}`}>{branch?.mobile2}</Link>
                  </label>
                </div>
              )}

              {/* Telephone */}
              {branch?.phone?.length > 0 && (
                <div className="mt-2">
                  <FontAwesomeIcon
                    icon={faBlenderPhone}
                    className="branch-title-icon"
                  />
                  <label>Telephone: &nbsp;</label>
                  <label className="contact">
                    <Link to={`tel:${branch?.phone}`}>{branch?.phone}</Link>
                  </label>
                </div>
              )}

              {/* Fax */}
              {branch?.fax?.length > 0 && (
                <div className="mt-2">
                  <FontAwesomeIcon icon={faFax} className="branch-title-icon" />
                  <label>Fax: &nbsp;</label>
                  <label className="contact">
                    <Link to={`tel:${branch?.fax}`}>{branch?.fax}</Link>
                  </label>
                </div>
              )}

              {/* Email */}
              {branch?.email?.length > 0 && (
                <div className="mt-2">
                  <FontAwesomeIcon
                    icon={faEnvelope}
                    className="branch-title-icon"
                  />
                  <label>Email: &nbsp;</label>
                  <label className="contact">
                    <Link to={`mailto:${branch?.email}`}>{branch?.email}</Link>
                  </label>
                </div>
              )}
            </Col>
          ))}
        </Row>

        <Row xs={1} className="g-4 mt-2">
          <Col>
            <ContactFormComponent isFullWidth={true} />
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default ContactPage;
