import React from 'react';

// Styles
import './TextWithIconComponent.styles.css';

const TextWithIconComponent = ({
	mainIcon,
	mainText,
	label = null,
	isCapitalized = true,
	isCentered = true,
	styles = {
		text: {},
	},
	...props
}) => {
	return (
		<div
			className={`icon-text d-flex ${
				isCentered ? 'align-items-center' : ''
			} py-2`}
		>
			{/* Main Icon */}
			{mainIcon}

			{/* Main Text */}
			<span
				className={`ps-2 ${isCapitalized ? 'text-capitalize' : ''}`}
				style={{ ...styles.text }}
			>
				{mainText}
			</span>
		</div>
	);
};

export default TextWithIconComponent;
