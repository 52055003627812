import React from 'react';
import { Breadcrumb, Container } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { LinkContainer } from 'react-router-bootstrap';
import { useLocation } from 'react-router-dom';

// Styles
import './BreadcrumbComponent.styles.css';

const BreadcrumbComponent = ({ items, title }) => {
	const { pathname } = useLocation();

	// Redux
	const { settings } = useSelector((state) => state.settingsData);

	return (
		<Container
			dir='ltr'
			lang='en'
			id='breadcrumb-component'
			as='section'
			fluid
			className='d-flex flex-column justify-content-center'
			style={{
				paddingTop: '3rem',
				'--image-url': `url(${settings.breadcrumb_img})`,
				'--loader-url': `url(${settings.loader})`,
			}}
		>
			<Container fluid='md' className='custom-container'>
				{/* Breadcrumb Items */}
				<Breadcrumb
					style={{
						marginBottom: '2rem',
					}}
				>
					{items.map((item, index) => (
						<LinkContainer key={index} to={item.href} exact='true'>
							<Breadcrumb.Item
								className='text-uppercase'
								active={pathname === item.href}
							>
								{item.title}
							</Breadcrumb.Item>
						</LinkContainer>
					))}
				</Breadcrumb>

				{/* Breadcrumb Title */}
				<h1
					className='title m-0 p-0 text-uppercase'
					style={{
						fontSize: '2rem',
					}}
				>
					{title}
				</h1>
			</Container>
		</Container>
	);
};

export default BreadcrumbComponent;
