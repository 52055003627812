import { createSlice } from '@reduxjs/toolkit';

// Slice
export const newsSlice = createSlice({
	name: 'news',
	initialState: {
		news: [],
		isNewsLoading: true,
	},
	reducers: {
		newsSuccess: (state, action) => {
			state.news = action.payload;
			state.isNewsLoading = false;
		},

		startLoading: (state) => {
			state.isNewsLoading = true;
		},

		hasError: (state, action) => {
			state.error = action.payload;
			state.isNewsLoading = false;
		},
	},
});

export default newsSlice.reducer;
