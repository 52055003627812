import { toast } from 'react-toastify';

const BASE_URL = {
	local: 'http://localhost:8000/api',
	demo: 'https://solextechnology.com/backend/api',
};

// Construct proper URL for API calls
export const constructURL = (infoType, searchParams = {}) => {
	const url = new URL(`${BASE_URL.demo}/${infoType}`);
	url.search = new URLSearchParams({ ...searchParams });

	return url;
};

// Trim additional spaces <p>&nbsp;</p> entered via ckeditor
export const trimEmptyTags = (htmlText, tag = 'p') => {
	const re = new RegExp(`<${tag}>&nbsp;</${tag}>`, 'gi');

	return htmlText.replace(re, '');
};

// Display From Messages
export const displayToastMessage = (statusCode, message) => {
	switch (statusCode) {
		case 200:
		case 201:
			toast.success(message);
			break;
		case 400:
			toast.error(message);
			break;
		default:
			toast.error('Error occurred during contact ... please try again later');
			break;
	}
};
