import React from 'react';
import { FormControl, InputGroup } from 'react-bootstrap';

// Styles
import './FilterComponent.styles.css';

const FilterComponent = ({
	inputLabel,
	inputPlaceholder,
	originalContent,
	setResults,
}) => {
	return (
		<InputGroup size='lg' className='mb-3'>
			<FormControl
				placeholder={inputPlaceholder}
				aria-label={inputLabel}
				type='search'
				onChange={(event) => {
					event.target.value.trim() === ''
						? setResults(originalContent)
						: setResults(
								originalContent.filter((content) =>
									content.product.includes(event.target.value.trim()) ||
									content.code.includes(event.target.value.trim())
								)
						  );
				}}
			/>
		</InputGroup>
	);
};

export default FilterComponent;
