import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useSelector } from 'react-redux';

const MetaTagsComponent = () => {
	// Redux
	const { settings, isSettingsLoading } = useSelector(
		(state) => state.settingsData
	);

	// <head></head> Details
	return (
		!isSettingsLoading && (
			<HelmetProvider>
				<Helmet
					htmlAttributes={{
						lang: 'en',
						dir: 'ltr',
					}}
				>
					{/* Open Graph Meta Tags */}
					<meta property='og:title' content={settings.website_title} />
					<meta property='og:description' content={settings.meta_description} />
					<meta property='og:image' content={settings.logo} />

					{/* Meta Tags */}
					<meta name='title' content={settings.meta_title} />
					<meta name='description' content={settings.meta_description} />
					<meta name='keywords' content={settings.meta_keywords} />

					{/* Tab Title */}
					<title>{settings.website_title}</title>

					{/* Favicon */}
					<link rel='icon' type='image/png' href={settings.favicon} />
				</Helmet>
			</HelmetProvider>
		)
	);
};

export default MetaTagsComponent;
