import React from "react";
import { Outlet, Route, Routes, useLocation } from "react-router-dom";

// Components
import AboutPage from "./pages/AboutPage/AboutPage";
import CatalogDetailsPage from "./pages/CatalogDetailsPage/CatalogDetailsPage";
import CatalogPage from "./pages/CatalogPage/CatalogPage";
import ContactPage from "./pages/ContactPage/ContactPage";
import HomePage from "./pages/HomePage/HomePage";
import MainTemplatePage from "./pages/MainTemplatePage/MainTemplatePage";
import NotFoundPage from "./pages/NotFoundPage/NotFoundPage";
import SearchResultPage from "./pages/SearchResultPage/SearchResultPage";
import WarrantiesPage from "./pages/WarrantyPage/WarrantiesPage";
import WarrantyPage from "./pages/WarrantyPage/WarrantyPage";
import NewsPage from "./pages/NewsPage/NewsPage";
import SingleNewsPage from "./pages/NewsPage/SingleNewsPage";
import DistributorsPage from "./pages/DistributorsPage/DistributorsPage";

const App = () => {
  const location = useLocation();

  return (
    <Routes location={location}>
      {/* Home Page */}
      <Route
        exact
        path="/"
        element={
          <MainTemplatePage>
            <HomePage />
          </MainTemplatePage>
        }
      />

      {/* About Page */}
      <Route
        path="/about"
        element={
          <MainTemplatePage>
            <AboutPage />
          </MainTemplatePage>
        }
      />

      {/* Warranties Page */}
      <Route path="warranties" element={<Outlet />}>
        <Route
          index
          element={
            <MainTemplatePage>
              <WarrantiesPage />
            </MainTemplatePage>
          }
        />

        {/* Single Warranty page */}
        <Route
          path=":warranty_id"
          element={
            <MainTemplatePage>
              <WarrantyPage />
            </MainTemplatePage>
          }
        />
      </Route>

       {/* News Page */}
       <Route path="news" element={<Outlet />}>
        <Route
          index
          element={
            <MainTemplatePage>
              <NewsPage />
            </MainTemplatePage>
          }
        />

        {/* Single News page */}
        <Route
          path=":news_id"
          element={
            <MainTemplatePage>
              <SingleNewsPage />
            </MainTemplatePage>
          }
        />
      </Route>

      {/* Distributors Page */}
      <Route
        path="/distributors"
        element={
          <MainTemplatePage>
            <DistributorsPage />
          </MainTemplatePage>
        }
      />
      
      {/* Contact Page */}
      <Route
        path="/contact"
        element={
          <MainTemplatePage>
            <ContactPage />
          </MainTemplatePage>
        }
      />

      {/* Catalog Page */}
      <Route
        exact
        path="/products"
        element={
          <MainTemplatePage>
            <CatalogPage />
          </MainTemplatePage>
        }
      />

      {/* Catalog Details Page */}
      <Route
        path="/products/:id"
        element={
          <MainTemplatePage>
            <CatalogDetailsPage />
          </MainTemplatePage>
        }
      />

      {/* Search Result Page */}
      <Route
        path="/search"
        element={
          <MainTemplatePage>
            <SearchResultPage />
          </MainTemplatePage>
        }
      />

      {/* Not Found Page */}
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};

export default App;
