import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { fetchDistributorsData } from "../../actions/distributorsData.action";

import { Card, Col, Container, Nav, Row, Tab } from "react-bootstrap";

// Components
import BreadcrumbComponent from "../../components/BreadcrumbComponent/BreadcrumbComponent";
import LoadingComponent from "../../components/LoadingComponent/LoadingComponent";
import { constructURL, trimEmptyTags } from "../../helpers/General";

import "react-lazy-load-image-component/src/effects/blur.css";
import "./DistributorsPage.scss";

const DistributorsPage = () => {
  // Breadcrumb Content
  const breadcrumbTitle = "Distributors";
  const breadcrumbItems = [
    { title: "home", href: "/" },
    { title: "Distributors", href: "/distributors" },
  ];

  // States
  const [isPageLoading, setIsPageLoading] = useState(true);

  // Redux
  const dispatch = useDispatch();
  const { distributors, isDistributorsLoading } = useSelector(
    (state) => state.distributors
  );

  useEffect(() => {
    // Scroll To Top
    window.scrollTo(0, 0);

    // Hide Page Loader
    setTimeout(() => {
      setIsPageLoading(false);
    }, 1000);
  }, []);

  useEffect(() => {
    dispatch(fetchDistributorsData(constructURL("distributors")));
  }, [dispatch]);
  return isDistributorsLoading || isPageLoading ? (
    <LoadingComponent />
  ) : (
    <Container as="main" fluid id="distributors-page" className="px-0">
      {/* Breadcrumb */}
      <BreadcrumbComponent items={breadcrumbItems} title={breadcrumbTitle} />

      {/* Distributors Page */}
      <Container fluid="md" className="custom-container custom-margin py-4">
        <Row xs={1} className="custom-gutter g-4 distributors-row">
          <Tab.Container
            id="left-tabs-example"
            defaultActiveKey={distributors[0]?.id}
          >
            <Row>
              <Col sm={3}>
                <Nav variant="pills" className="flex-column">
                  {distributors?.map((distributor, index) => (
                    <Nav.Item key={index}>
                      <Nav.Link
                        eventKey={distributor?.id}
                        className="distributor-left-nav"
                      >
                        {distributor?.title}
                      </Nav.Link>
                    </Nav.Item>
                  ))}
                </Nav>
              </Col>

              <Col sm={9}>
                <Tab.Content>
                  {distributors?.map((distributor, index) => (
                    <Tab.Pane eventKey={distributor?.id} key={index} className="distributor-right-nav">
                      <h3 className="title">{distributor?.title}</h3>

                      <div
                        dangerouslySetInnerHTML={{
                          __html: trimEmptyTags(distributor?.description),
                        }}
                      ></div>
                    </Tab.Pane>
                  ))}
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </Row>
      </Container>
    </Container>
  );
};

export default DistributorsPage;
