import axios from 'axios';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import React, { useRef } from 'react';
import {
	Col,
	FormGroup,
	FormLabel,
	FormText,
	Row,
	Spinner,
} from 'react-bootstrap';
import { object, string } from 'yup';
import { constructURL, displayToastMessage } from './../../helpers/General';

// Styles
import './ContactFormComponent.styles.css';

// Components
import ButtonComponent from './../../components/ButtonComponent/ButtonComponent';

const ContactFormComponent = ({ isFullWidth = false }) => {
	const firstNameRef = useRef();
	const lastNameRef = useRef();
	const emailRef = useRef();
	const messageRef = useRef();

	// Highlight Server-Side Errors
	const displayErrors = (fieldName) => {
		switch (fieldName) {
			case 'fname':
				firstNameRef.current.classList.add('is-invalid');
				break;

			case 'lname':
				lastNameRef.current.classList.add('is-invalid');
				break;

			case 'email':
				emailRef.current.classList.add('is-invalid');
				break;

			case 'message':
				messageRef.current.classList.add('is-invalid');
				break;

			default:
				break;
		}
	};

	// Form Schema
	const contactSchema = object().shape({
		fname: string()
			.min(2, 'First name must be 2 characters at minimum')
			.max(100, 'First name must be less than 100 characters')
			.required('First name is required'),
		lname: string()
			.min(2, 'Last name must be 2 characters at minimum')
			.max(100, 'Last name must be less than 100 characters')
			.required('Last name is required'),
		email: string()
			.email('Invalid email address format')
			.required('Email is required'),
		message: string()
			.min(10, 'Message must be 10 characters at minimum')
			.max(500, 'Message must be less than 500 characters')
			.required('Message is required'),
	});

	// Form Submission
	const submitContactForm = async (values, setSubmitting, resetForm) => {
		axios({
			method: 'post',
			url: constructURL('contact'),
			data: {
				fname: values.fname,
				lname: values.lname,
				email: values.email,
				message: values.message,
			},
			headers: { 'Content-Type': 'multipart/form-data' },
		})
			.then((response) => {
				// reset submitting
				setSubmitting(false);
				resetForm(true);

				displayToastMessage(response.status, response.data.data);
			})
			.catch((error) => {
				// reset submitting
				setSubmitting(false);

				if (typeof error.response.data.data === typeof {}) {
					Object.keys(error.response.data.data).forEach((key) => {
						displayErrors(key);
						displayToastMessage(
							error.response.status,
							error.response.data.data[key][0]
						);
					});
				} else {
					displayToastMessage(error.response.status, error.response.data.data);
				}
			});
	};

	return (
		<Formik
			initialValues={{
				fname: '',
				lname: '',
				email: '',
				message: '',
			}}
			validationSchema={contactSchema}
			onSubmit={(values, { setSubmitting, resetForm }) => {
				setSubmitting(true);
				submitContactForm(values, setSubmitting, resetForm);
			}}
		>
			{({
				values,
				errors,
				touched,
				handleChange,
				handleBlur,
				handleSubmit,
				isSubmitting,
				setFieldValue,
			}) => (
				<Form
					onSubmit={(event) => {
						event.preventDefault();
						handleSubmit();
					}}
					className='p-4 mx-auto overflow-hidden'
					style={{
						maxWidth: isFullWidth ? '100%' : '46rem',
						backgroundColor: 'rgba(22, 63, 112, 0.15)',
						borderRadius: '0.375rem',
					}}
				>
					<Row xs={1} sm={2} lg={isFullWidth ? 3 : 2} className='g-3 mb-3'>
						{/* First Name */}
						<FormGroup as={Col}>
							<FormLabel htmlFor='first_name'>First Name</FormLabel>
							<Field
								id='first_name'
								type='text'
								innerRef={firstNameRef}
								placeholder='First Name'
								autoComplete='off'
								name='fname'
								onChange={(event) => {
									handleChange(event);
								}}
								onBlur={handleBlur}
								value={values.fname}
								className={`form-control text-capitalize ${
									touched.fname && errors.fname ? 'is-invalid' : ''
								}`}
							/>
							<ErrorMessage
								component='div'
								name='fname'
								className='invalid-feedback'
							/>
						</FormGroup>

						{/* Last Name */}
						<FormGroup as={Col}>
							<FormLabel htmlFor='last_name'>Last Name</FormLabel>
							<Field
								id='last_name'
								type='text'
								innerRef={lastNameRef}
								placeholder='Last Name'
								autoComplete='off'
								name='lname'
								onChange={(event) => {
									handleChange(event);
								}}
								onBlur={handleBlur}
								value={values.lname}
								className={`form-control text-capitalize ${
									touched.lname && errors.lname ? 'is-invalid' : ''
								}`}
							/>
							<ErrorMessage
								component='div'
								name='lname'
								className='invalid-feedback'
							/>
						</FormGroup>

						{/* Email */}
						<FormGroup as={Col} sm={12} lg={isFullWidth ? 4 : 12}>
							<FormLabel htmlFor='email'>Email</FormLabel>
							<Field
								id='email'
								type='email'
								innerRef={emailRef}
								placeholder='mail@domain.com'
								autoComplete='off'
								name='email'
								onChange={(event) => {
									handleChange(event);
								}}
								onBlur={handleBlur}
								value={values.email}
								className={`form-control ${
									touched.email && errors.email ? 'is-invalid' : ''
								}`}
							/>
							<ErrorMessage
								component='div'
								name='email'
								className='invalid-feedback'
							/>
						</FormGroup>
					</Row>

					<Row xs={1} className='g-3 mb-3'>
						{/* Message */}
						<FormGroup as={Col} className='mb-3 position-relative'>
							<FormLabel htmlFor='message'>Your Message</FormLabel>
							<Field
								id='message'
								as='textarea'
								innerRef={messageRef}
								rows={8}
								style={{
									resize: 'none',
								}}
								placeholder='Enter your thoughts here ...'
								autoComplete='off'
								name='message'
								onChange={(event) => {
									handleChange(event);
								}}
								onBlur={handleBlur}
								value={values.message}
								className={`form-control ${
									touched.message && errors.message ? 'is-invalid' : ''
								}`}
							/>
							<FormText className='text-muted'>
								{`${values.message.length} ${'out of 500 characters'}`}
							</FormText>
							<ErrorMessage
								component='div'
								name='message'
								className='invalid-feedback'
							/>
						</FormGroup>
					</Row>

					{/* Submit Form */}
					<FormGroup className='d-flex justify-content-center mt-3'>
						<ButtonComponent
							title={isSubmitting ? 'loading ...' : 'submit message'}
							icon={
								isSubmitting ? (
									<Spinner
										animation='grow'
										variant='light'
										size='sm'
										className='me-2'
									/>
								) : (
									<></>
								)
							}
							type='submit'
							disabled={isSubmitting ? true : false}
						/>
					</FormGroup>
				</Form>
			)}
		</Formik>
	);
};

export default ContactFormComponent;
