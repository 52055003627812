import React from 'react';

// Styles
import './LoadingComponent.styles.css';

const LoadingComponent = () => {
	return (
		<div
			id='loading-component'
			className='d-flex justify-content-center align-items-center'
			style={{
				position: 'absolute',
				top: 0,
				left: 0,
				width: '100%',
				height: '100vh',
				zIndex: 999,
			}}
		>
			<div id='wifi-loader'>
				<svg className='circle-outer' viewBox='0 0 86 86'>
					<circle className='back' cx='43' cy='43' r='40'></circle>
					<circle className='front' cx='43' cy='43' r='40'></circle>
					<circle className='new' cx='43' cy='43' r='40'></circle>
				</svg>
				<svg className='circle-middle' viewBox='0 0 60 60'>
					<circle className='back' cx='30' cy='30' r='27'></circle>
					<circle className='front' cx='30' cy='30' r='27'></circle>
				</svg>
				<svg className='circle-inner' viewBox='0 0 34 34'>
					<circle className='back' cx='17' cy='17' r='14'></circle>
					<circle className='front' cx='17' cy='17' r='14'></circle>
				</svg>
			</div>
		</div>
	);
};

export default LoadingComponent;
