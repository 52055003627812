import axios from 'axios';
import { settingsSlice } from '../reducers/settingsData.reducer';

const { settingsSuccess, startLoading, hasError } = settingsSlice.actions;

// Actions
export const fetchSettingsData =
	(
		url = 'https://solextechnology.com/backend/api/settings',
		language = 'en'
	) =>
	async (dispatch) => {
		dispatch(startLoading());

		await axios({
			method: 'get',
			url: url,
			headers: {
				locale: language,
			},
		})
			.then((response) => {
				dispatch(settingsSuccess(response.data.data));
			})
			.catch((error) => dispatch(hasError(error.message)));
	};
