import { createSlice } from '@reduxjs/toolkit';

// Slice
export const productsSlice = createSlice({
	name: 'products',
	initialState: {
		products: [],
		isProductsLoading: true,
	},
	reducers: {
		productsSuccess: (state, action) => {
			state.products = action.payload;
			state.isProductsLoading = false;
		},

		startLoading: (state) => {
			state.isProductsLoading = true;
		},

		hasError: (state, action) => {
			state.error = action.payload;
			state.isProductsLoading = false;
		},
	},
});

export default productsSlice.reducer;
