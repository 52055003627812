import axios from 'axios';
import { homeDataSlice } from '../reducers/homeData.reducer';

const { homeDataSuccess, startLoading, hasError } = homeDataSlice.actions;

// Actions
export const fetchHomeData =
	(url = 'https://solextechnology.com/backend/api/home', language = 'en') =>
	async (dispatch) => {
		dispatch(startLoading());

		await axios({
			method: 'get',
			url: url,
			headers: {
				locale: language,
			},
		})
			.then((response) => dispatch(homeDataSuccess(response.data.data)))

			.catch((error) => dispatch(hasError(error.message)));
	};
