import React from 'react';
import { Col, Image, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { trimEmptyTags } from './../../helpers/General';

// Styles
import './AboutCardComponent.styles.css';

const AboutCardComponent = ({ index, about }) => {
	const { settings, isSettingsLoading } = useSelector(
		(state) => state.settingsData
	);

	return (
		<Row className='g-0 overflow-hidden'>
			<Col xs={12} sm={6} lg={8} className='overflow-hidden'>
				<Image
					fluid
					src={about.image}
					alt={`${about.title} thumbnail`}
					onError={(event) => {
						event.target.src = settings.loader;
					}}
					className='w-100 h-100 text-capitalize'
					style={{
						objectFit: 'cover',
					}}
				/>
			</Col>
			<Col
				xs={12}
				sm={6}
				lg={4}
				className='about-card-description p-5 d-flex flex-column justify-content-center'
			>
				<h4
					className='title display-6 fw-bold text-uppercase mb-4'
					style={{
						fontSize: '30px',
						textAlign: 'inherit',
					}}
				>
					{about.title.toLowerCase()}
				</h4>
				<p
					className='text-black-50 my-0 p-0'
					dangerouslySetInnerHTML={{
						__html: trimEmptyTags(about.description),
					}}
				></p>
			</Col>
		</Row>
	);
};

export default AboutCardComponent;
