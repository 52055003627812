import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { fetchNewsData } from "../../actions/newsData.action";

import { Card, Col, Container, Row } from "react-bootstrap";
import { Fade } from "react-awesome-reveal";

// Components
import BreadcrumbComponent from "../../components/BreadcrumbComponent/BreadcrumbComponent";
import LoadingComponent from "../../components/LoadingComponent/LoadingComponent";
import { constructURL } from "../../helpers/General";
import { LazyLoadImage } from "react-lazy-load-image-component";
import PlaceholderImage from "../../assets/favicon.png";
import { Link } from "react-router-dom";

import "react-lazy-load-image-component/src/effects/blur.css";
import "./NewsPage.scss";

const NewsPage = () => {
  // Breadcrumb Content
  const breadcrumbTitle = "News";
  const breadcrumbItems = [
    { title: "home", href: "/" },
    { title: "News", href: "/news" },
  ];

  // States
  const [isPageLoading, setIsPageLoading] = useState(true);

  // Redux
  const dispatch = useDispatch();
  const { news, isNewsLoading } = useSelector((state) => state.news);

  useEffect(() => {
    // Scroll To Top
    window.scrollTo(0, 0);

    // Hide Page Loader
    setTimeout(() => {
      setIsPageLoading(false);
    }, 1000);
  }, []);

  useEffect(() => {
    dispatch(fetchNewsData(constructURL("news")));
  }, [dispatch]);
  return isNewsLoading || isPageLoading ? (
    <LoadingComponent />
  ) : (
    <Container as="main" fluid id="news-page" className="px-0">
      {/* Breadcrumb */}
      <BreadcrumbComponent items={breadcrumbItems} title={breadcrumbTitle} />

      {/* News Page */}
      <Container fluid="md" className="custom-container custom-margin py-4">
        <Row xs={1} sm={2} md={3} className="custom-gutter g-4 news-row">
          {news.map((data, index) => (
            <Fade
              cascade
              damping={0.1}
              delay={index * 110}
              direction="down"
              key={index}
            >
              <Col>
                <Card>
                  <Link to={`/news/${data?.id}`}>
                    <div className="news-image">
                      <LazyLoadImage
                        key={data?.id}
                        src={data?.image}
                        alt={"News image"}
                        className="img-fluid w-100"
                        placeholderSrc={PlaceholderImage}
                        effect="blur"
                      />
                    </div>
                    <Card.Body>
                      <Card.Title className="title text-center">
                        {data?.title}
                      </Card.Title>
                    </Card.Body>
                  </Link>
                </Card>
              </Col>
            </Fade>
          ))}
        </Row>
      </Container>
    </Container>
  );
};

export default NewsPage;
