import React from 'react';
import { Card } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { LinkContainer } from 'react-router-bootstrap';

// Styles
import './CatalogCardComponent.styles.css';

const CatalogCardComponent = ({ catalog }) => {
	// Redux
	const { settings, isSettingsLoading } = useSelector(
		(state) => state.settingsData
	);

	return isSettingsLoading ? (
		<></>
	) : (
		<LinkContainer
			to={`/products/${catalog.id}`}
			exact='true'
			style={{
				minHeight: '18rem',
				cursor: 'pointer',
				'--card-image': `url(${catalog.image})`,
				'--loader-url': `url(${settings.loader})`,
			}}
		>
			<Card className='catalog-card-component'>
				<Card.ImgOverlay className='text-center d-flex flex-column justify-content-center align-items-center'>
					<Card.Title
						className='title text-uppercase text-limit'
						style={{
							'--lines': 1,
						}}
					>
						{catalog.title.toLowerCase()}
					</Card.Title>
					<Card.Text
						className='text-limit'
						style={{
							'--lines': 1,
						}}
					>
						{catalog.description}
					</Card.Text>
				</Card.ImgOverlay>
			</Card>
		</LinkContainer>
	);
};

export default CatalogCardComponent;
