import axios from 'axios';
import { catalogsSlice } from '../reducers/catalogs.reducer';

const { catalogsSuccess, startLoading, hasError } = catalogsSlice.actions;

// Actions
export const fetchCatalogs =
	(
		url = 'https://solextechnology.com/backend/api/catalogs',
		language = 'en'
	) =>
	async (dispatch) => {
		dispatch(startLoading());

		await axios({
			method: 'get',
			url: url,
			headers: {
				locale: language,
			},
		})
			.then((response) => dispatch(catalogsSuccess(response.data.data)))
			.catch((error) => dispatch(hasError(error.message)));
	};
