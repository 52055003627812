import axios from 'axios';
import { productsSlice } from '../reducers/products.reducer';

const { productsSuccess, startLoading, hasError } = productsSlice.actions;

// Actions
export const fetchProducts =
	(
		url = 'https://solextechnology.com/backend/api/products',
		language = 'en'
	) =>
	async (dispatch) => {
		dispatch(startLoading());

		await axios({
			method: 'get',
			url: url,
			headers: {
				locale: language,
			},
		})
			.then((response) => {
				dispatch(productsSuccess(response.data.data));
			})
			.catch((error) => dispatch(hasError(error.message)));
	};
