import React, { useEffect, useState } from "react";
import {
  Container,
  Image,
  Nav,
  NavDropdown,
  Navbar,
  Offcanvas,
} from "react-bootstrap";
import { FaDownload, FaSearch } from "react-icons/fa";
import { IoIosArrowDown } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { constructURL } from "./../../helpers/General";

// Actions
import { fetchCatalogs } from "../../actions/catalogs.action";
import { fetchProducts } from "../../actions/products.action";
import { fetchHomeData } from "./../../actions/homeData.action";
import { fetchSettingsData } from "./../../actions/settingsData.action";

// Styles
import "./NavbarComponent.styles.css";

// Components
import ButtonComponent from "./../ButtonComponent/ButtonComponent";
import SearchModalComponent from "./../SearchModalComponent/SearchModalComponent";

const NavbarComponent = () => {
  // States
  const [isSearchShown, setIsSearchShown] = useState(false);
  const [isOffcanvasOpen, setIsOffcanvasOpen] = useState(false);

  // const [isDropdownShown, setIsDropdownShown] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [showInfo, setShowInfo] = useState(false);

  // Dropdown toggle
  const showDropdownMenu = (e) => {
    setShowDropdown(!showDropdown);
  };
  const hideDropdownMenu = (e) => {
    setShowDropdown(false);
  };

  const showInformationDropdownMenu = (e) => {
    setShowInfo(!showInfo);
  };
  const hideInformationDropdownMenu = (e) => {
    setShowInfo(false);
  };

  // Redux
  const dispatch = useDispatch();
  const { isHomeDataLoading } = useSelector((state) => state.homeData);
  const { catalogs, isCatalogsLoading } = useSelector(
    (state) => state.catalogs
  );
  const { isProductsLoading } = useSelector((state) => state.products);
  const { settings, isSettingsLoading } = useSelector(
    (state) => state.settingsData
  );

  useEffect(() => {
    dispatch(fetchProducts(constructURL("products")));
    dispatch(fetchHomeData(constructURL("home")));
    dispatch(fetchSettingsData(constructURL("settings")));
    dispatch(fetchCatalogs(constructURL("catalogs")));
  }, [dispatch]);

  return (
    !isHomeDataLoading &&
    !isSettingsLoading &&
    !isCatalogsLoading &&
    !isProductsLoading && (
      <Navbar
        dir="ltr"
        lang="en"
        bg="white"
        expand="xl"
        fixed="top"
        className="border d-flex justify-content-center align-items-center flex-column"
        style={{
          boxShadow: "0 0 4px #0f0e0e",
        }}
      >
        <Container fluid="md" className="custom-container" id="navbar-content">
          {/* Company Logo */}
          <Navbar.Brand as={NavLink} to="/">
            <Image
              src={settings.logo}
              alt="company logo"
              onError={(event) => {
                event.target.src = settings.loader;
              }}
              className="text-capitalize"
              style={{
                maxWidth: "8rem",
                maxHeight: "3rem",
              }}
            />
          </Navbar.Brand>

          {/* Search Icon */}
          <Navbar.Text
            id="search-icon"
            role="button"
            className="d-flex ms-auto"
            onClick={() => setIsSearchShown(true)}
          >
            {/* Search Icon */}
            <FaSearch size={30} />
          </Navbar.Text>

          {/* Search Modal */}
          <SearchModalComponent
            show={isSearchShown}
            onHide={() => {
              setIsSearchShown(false);
            }}
          />

          {/* Offcanvas Toggler */}
          <Navbar.Toggle
            onClick={() => setIsOffcanvasOpen(true)}
            aria-controls="offcanvasNavbar-expand-lg"
          />

          <Navbar.Offcanvas
            dir="ltr"
            lang="en"
            id="offcanvasNavbar-expand-lg"
            show={isOffcanvasOpen}
            onHide={() => setIsOffcanvasOpen(false)}
            aria-labelledby="offcanvasNavbarLabel-expand-lg"
            placement="end"
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title id="offcanvasNavbarLabel-expand-expand">
                {/* Company Logo */}
                <Navbar.Brand as={NavLink} to="/">
                  <Image
                    src={require("./../../assets/images/logo.webp")}
                    style={{
                      maxWidth: "8rem",
                    }}
                    alt="Company Logo"
                  />
                </Navbar.Brand>
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Nav className="ms-auto">
                {/* Home */}
                <NavLink
                  end
                  to="/"
                  className={({ isActive }) =>
                    isActive ? "nav-link active" : "nav-link"
                  }
                  onClick={() => setIsOffcanvasOpen(false)}
                >
                  Home
                </NavLink>

                {/* About Us */}
                <NavLink
                  to="/about"
                  className={({ isActive }) =>
                    isActive ? "nav-link active" : "nav-link"
                  }
                  onClick={() => setIsOffcanvasOpen(false)}
                >
                  About Us
                </NavLink>

                {/* Distribution & Warranty Information */}
                {/* <NavDropdown
                  className={`text-decoration-none text-capitalize info-nav-dropdown ${
                    showInfo ? "is-open" : "is-closed"
                  }`}
                  title={
                    <>
                      <span>Distribution & Warranty</span>
                      <IoIosArrowDown />
                    </>
                  }
                  show={showInfo}
                  onMouseEnter={showInformationDropdownMenu}
                  onMouseLeave={hideInformationDropdownMenu}
                  onClick={showInformationDropdownMenu}
                > */}
                  {/* Warranty */}
                  {/* <NavDropdown.Item className="info-dropDown">
                    <NavLink
                      to="/warranties"
                      className={({ isActive }) =>
                        isActive ? "nav-link active" : "nav-link"
                      }
                      onClick={() => setIsOffcanvasOpen(false)}
                    >
                      Warranty Programme
                    </NavLink>
                  </NavDropdown.Item> */}
                  
                  {/* distributors */}
                  {/* <NavDropdown.Item className="info-dropDown">
                    <NavLink
                      to="/distributors"
                      className={({ isActive }) =>
                        isActive ? "nav-link active" : "nav-link"
                      }
                      onClick={() => setIsOffcanvasOpen(false)}
                    >
                      Distributors
                    </NavLink>
                  </NavDropdown.Item> */}
                {/* </NavDropdown> */}

                <NavLink
                      to="/warranties"
                      className={({ isActive }) =>
                        isActive ? "nav-link active" : "nav-link"
                      }
                      onClick={() => setIsOffcanvasOpen(false)}
                    >
                      Warranty Programme
                    </NavLink>

                {/* News */}
                {/* <NavLink
                  to="/news"
                  className={({ isActive }) =>
                    isActive ? "nav-link active" : "nav-link"
                  }
                  onClick={() => setIsOffcanvasOpen(false)}
                >
                  News
                </NavLink> */}

                {/* Contact Us */}
                <NavLink
                  to="/contact"
                  className={({ isActive }) =>
                    isActive ? "nav-link active" : "nav-link"
                  }
                  onClick={() => setIsOffcanvasOpen(false)}
                >
                  Contact Us
                </NavLink>

                {/* Catalog */}
                <NavDropdown
                  className={`position-static text-decoration-none text-capitalize ${
                    showDropdown ? "is-open" : "is-closed"
                  }`}
                  title={
                    <>
                      <span>Products</span>
                      <IoIosArrowDown />
                    </>
                  }
                  id="catalogs-nav-dropdown"
                  show={showDropdown}
                  onMouseEnter={showDropdownMenu}
                  onMouseLeave={hideDropdownMenu}
                  onClick={showDropdownMenu}
                >
                  {catalogs.map((catalog, index) => (
                    <NavDropdown.Item
                      key={index}
                      as="li"
                      onClick={(event) => event.preventDefault()}
                    >
                      <span className="mb-2 fw-bold">{catalog.title}</span>
                      <p
                        className="mb-2 p-0 flex-grow-1"
                        style={{ fontSize: "13px" }}
                      >
                        {catalog.description}
                      </p>
                      <ButtonComponent
                        title="Download"
                        href={catalog.pdf}
                        target={true}
                        icon={
                          <FaDownload
                            className="position-relative"
                            style={{
                              margin: "0 8px 0 0",
                              zIndex: "10",
                            }}
                          />
                        }
                        styles={{
                          button: {
                            width: "fit-content",
                            padding: "0.5rem",
                            zIndex: 99,
                          },
                        }}
                        onClick={(event) => event.stopPropagation()}
                      />
                    </NavDropdown.Item>
                  ))}
                </NavDropdown>

                {/* Datasheets */}
                <NavLink
                  to="/products"
                  className={({ isActive }) =>
                    isActive ? "nav-link active" : "nav-link"
                  }
                  style={{
                    textDecoration: "none",
                    textTransform: "capitalize",
                  }}
                  onClick={() => setIsOffcanvasOpen(false)}
                >
                  Datasheets
                </NavLink>
              </Nav>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
    )
  );
};

export default NavbarComponent;
