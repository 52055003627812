import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { fetchWarrantyData } from "../../actions/warrantyData.action";
import { useParams } from "react-router-dom";

import { Col, Container, Row } from "react-bootstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Fade, Slide, Zoom } from "react-awesome-reveal";

// Components
import BreadcrumbComponent from "../../components/BreadcrumbComponent/BreadcrumbComponent";
import LoadingComponent from "../../components/LoadingComponent/LoadingComponent";
import { constructURL, trimEmptyTags } from "../../helpers/General";
import PlaceholderImage from "../../assets/favicon.png";
import LightboxComponent from "../../components/LightBox/LightboxComponent";
import { FaDownload } from "react-icons/fa";
import ButtonComponent from "../../components/ButtonComponent/ButtonComponent";

import "react-lazy-load-image-component/src/effects/blur.css";
import "./WarrantyPage.scss";

const WarrantyPage = () => {
  const { warranty_id } = useParams();
  // States
  const [isPageLoading, setIsPageLoading] = useState(true);

  // Redux
  const dispatch = useDispatch();
  const { warranties, isWarrantiesLoading } = useSelector(
    (state) => state.warranties
  );

  useEffect(() => {
    // Scroll To Top
    window.scrollTo(0, 0);

    // Hide Page Loader
    setTimeout(() => {
      setIsPageLoading(false);
    }, 1000);
  }, []);

  useEffect(() => {
    dispatch(fetchWarrantyData(constructURL("warranties")));
  }, [dispatch]);

  // States
  const [lightbox, setLightbox] = useState({ isOpen: false, index: 0 });

  // Find warranty
  const warranty = warranties?.find(
    (warranty) => parseInt(warranty?.id) === parseInt(warranty_id)
  );
  // Breadcrumb Content
  const breadcrumbTitle = warranty?.title;
  const breadcrumbItems = [
    { title: "home", href: "/" },
    { title: "Warranties", href: "/warranties" },
    { title: warranty?.title, href: "" },
  ];

  return isWarrantiesLoading || isPageLoading ? (
    <LoadingComponent />
  ) : (
    <Container as="main" fluid id="general-page" className="px-0">
      {/* Breadcrumb */}
      <BreadcrumbComponent items={breadcrumbItems} title={breadcrumbTitle} />

      {/* Warranty Page */}
      <Container fluid="md" className="custom-container custom-margin py-4">
        <Row xs={1} sm={2} className="custom-gutter g-4 warrant-row">
          {/* Image */}
          <Col>
            <Fade direction="left" cascade damping={0.1} delay={90}>
              <div className="warranty-image">
                <LazyLoadImage
                  key={warranty?.id}
                  src={warranty?.image}
                  alt={"Warranty image"}
                  className="img-fluid w-100"
                  placeholderSrc={PlaceholderImage}
                  effect="blur"
                  onClick={() => setLightbox({ isOpen: true, index: 0 })}
                />
              </div>
            </Fade>
          </Col>

          {/* Title and Description */}
          <Col>
            <Fade direction="right" damping={0.1} delay={90}>
              {/* Title */}
              <h3 className="title">{warranty?.title}</h3>

              {/* Description */}
              <div
                dangerouslySetInnerHTML={{
                  __html: trimEmptyTags(warranty?.description),
                }}
              ></div>
            </Fade>
          </Col>
        </Row>

        {/* Files */}
        <Row xs={1} sm={2} className="custom-gutter g-4">
          {warranty?.files?.map((file, index) => (
            <Zoom cascade key={index}>
              <Col className="file-col">
                <p className="file-text">Download File {index + 1}</p>
                <ButtonComponent
                  href={file?.path}
                  target={true}
                  icon={
                    <FaDownload
                      className="position-relative"
                      style={{
                        zIndex: "10",
                      }}
                    />
                  }
                  styles={{
                    button: {
                      width: "fit-content",
                      // margin: "0 auto",
                      // marginRight: "0.5rem",
                      // padding: "0.5rem 1rem",
                    },
                  }}
                />
              </Col>
            </Zoom>
          ))}
        </Row>

        <LightboxComponent
          slides={[warranty]}
          lightbox={lightbox}
          setLightbox={setLightbox}
          pathname="<object>.image"
        />
      </Container>
    </Container>
  );
};

export default WarrantyPage;
