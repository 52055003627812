import '@fontsource/dm-serif-display';
import '@fontsource/montserrat';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

// Redux
import { Provider } from 'react-redux';
import store from './store/index.store';

// Styles
import 'animate.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'normalize.css';
import 'react-toastify/dist/ReactToastify.css';
import './index.css';

// Components
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<Provider store={store}>
		<React.StrictMode>
			<BrowserRouter>
				<App />
			</BrowserRouter>
		</React.StrictMode>
	</Provider>
);
