import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { FaDownload } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import { constructURL } from '../../helpers/General';

// Styles
import './CatalogDetailsPage.styles.css';

// Components
import BreadcrumbComponent from '../../components/BreadcrumbComponent/BreadcrumbComponent';
import ButtonComponent from '../../components/ButtonComponent/ButtonComponent';
import LoadingComponent from '../../components/LoadingComponent/LoadingComponent';
import NoResultComponent from '../../components/NoResultComponent/NoResultComponent';
import FilterComponent from './../../components/FilterComponent/FilterComponent';

const CatalogDetailsPage = () => {
	// Catalog ID
	const { id } = useParams();

	// States
	const [datasheets, setDatasheets] = useState(null);
	const [filteredDatasheets, setFilteredDatasheets] = useState(null);
	const [catalogTitle, setCatalogTitle] = useState(null);
	const [isPageLoading, setIsPageLoading] = useState(true);

	// Breadcrumb Content
	const breadcrumbTitle = catalogTitle && catalogTitle.toLowerCase();
	const breadcrumbItems = [
		{ title: 'home', href: '/' },
		{ title: 'products', href: '/products' },
		{ title: 'datasheets', href: `/products/${id}` },
	];

	useEffect(() => {
		// Scroll To Top
		window.scrollTo(0, 0);

		// Hide Page Loader
		setTimeout(() => {
			setIsPageLoading(false);
		}, 1000);
	}, []);

	// Fetch Catalog Datasheets
	useEffect(() => {
		const fetchDatasheets = async () => {
			setDatasheets(null);
			await axios({
				method: 'get',
				url: constructURL('show-catalog', { id: id }),
				headers: {
					locale: 'en',
				},
			})
				.then((response) => {
					setCatalogTitle(response.data.data.title);
					setDatasheets(response.data.data.products);
					setFilteredDatasheets(response.data.data.products);
				})
				.catch((error) => console.log(error.message));
		};

		fetchDatasheets();

		// eslint-disable-next-line
	}, []);

	return !datasheets || !catalogTitle || isPageLoading ? (
		<LoadingComponent />
	) : (
		<Container as='main' fluid id='catalog-details-page' className='px-0'>
			{/* Breadcrumb */}
			<BreadcrumbComponent items={breadcrumbItems} title={breadcrumbTitle} />

			{/* Catalog Details Page */}
			<Container fluid='md' className='custom-container custom-margin py-4'>
				<Row xs={1} className='g-4'>
					<Col>
						<h3 className='title text-uppercase mb-5'>
							{catalogTitle.toLowerCase()}
						</h3>
					</Col>
				</Row>

				{/* Datasheets Filter */}
				<Row
					xs={1}
					className='g-4'
					style={{
						marginBottom: '3rem',
					}}
				>
					<Col>
						<FilterComponent
							originalContent={datasheets}
							setResults={setFilteredDatasheets}
							inputLabel='Product Code'
							inputPlaceholder='Enter product code here ...'
						/>
					</Col>
				</Row>

				{/* Displayed Results */}
				<Row xs={1} sm={2} className='custom-gutter g-4'>
					{filteredDatasheets && filteredDatasheets.length !== 0 ? (
						filteredDatasheets.map((datasheet, index) => (
							<Col key={index} className='d-flex flex-row-reverse'>
								<span className='flex-grow-1 d-flex align-items-center'>
									{datasheet.title} - {datasheet.code}
								</span>
								<ButtonComponent
									href={datasheet.pdf}
									target={true}
									icon={
										<FaDownload
											className='position-relative'
											style={{
												zIndex: '10',
											}}
										/>
									}
									styles={{
										button: {
											width: 'fit-content',
											margin: '0 auto',
											marginRight: '0.5rem',
											padding: '0.5rem 1rem',
										},
									}}
								/>
							</Col>
						))
					) : (
						<NoResultComponent />
					)}
				</Row>
			</Container>
		</Container>
	);
};

export default CatalogDetailsPage;
